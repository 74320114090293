import {differenceInDays, format, isAfter, parseISO} from 'date-fns';

type WeatherData = {
	weatherCode: number;
	maxTemperature: number;
	apparentMaxTemperature: number;
};

const upcomingEvents = document.querySelectorAll('.event:not(.expired)');
const now = new Date();

const appendWeatherData = (
	element: HTMLElement,
	temperature: number,
	apparentTemperature: number,
	code: number,
) => {
	const icon =
		'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" role="img" aria-hidden="true"><path d="M176 322.9V112c0-8.75-7.25-16-16-16s-16 7.25-16 16v210.9c-18.62 6.625-32 24.25-32 45.13 0 26.5 21.5 48 48 48s48-21.5 48-48c0-20.93-13.4-38.53-32-45.13zm96-44.4V112C272 50.13 221.88 0 160.9 0S48 50.13 48 112v166.5C28.25 303.25 16 334 16 368c0 79.5 64.5 143.1 144 143.1S304 447.5 304 368c0-34-12.2-64.9-32-89.5zM160 448c-44.13 0-80-35.87-80-79.1 0-25.5 12.25-48.88 32-63.75v-192.3c0-26.5 21.5-48 48-48s48 21.5 48 48v192.3c19.75 14.75 32 38.25 32 63.75 0 43.2-35.9 79.1-80 79.1z"/></svg>';
	const rain =
		(code > 60 && code < 70) || (code > 79 && code < 85)
			? ' und Regen'
			: '';
	const weatherElement = document.createElement('SPAN');
	weatherElement.classList.add('event-weather');
	weatherElement.title = `Gefühlt ${apparentTemperature}\u00A0°C`;
	weatherElement.textContent = ` ${temperature}\u00A0°C${rain}`;
	weatherElement.insertAdjacentHTML('afterbegin', icon);
	element.after(weatherElement);
};

for (const upcomingEvent of upcomingEvents) {
	const latElement = upcomingEvent.querySelector('.p-latitude');
	const lngElement = upcomingEvent.querySelector('.p-longitude');

	if (latElement && lngElement) {
		const dateElement: HTMLTimeElement | null =
			upcomingEvent.querySelector('.dt-start');
		const date = dateElement ? parseISO(dateElement.dateTime) : now;
		const showWeather =
			isAfter(date, now) && differenceInDays(date, now) <= 4;

		if (showWeather) {
			const dateFormatted = format(date, 'yyyy-MM-dd');
			const lat = latElement.textContent ?? '';
			const lng = lngElement.textContent ?? '';

			(async () => {
				const apiUrl = '/forecast';
				const parameters = [
					`latitude=${lat}`,
					`longitude=${lng}`,
					`date=${dateFormatted}`,
				];

				try {
					const response = await window.fetch(
						apiUrl + '?' + parameters.join('&'),
					);
					if (response.ok) {
						const data = (await response.json()) as WeatherData;
						const {
							maxTemperature,
							apparentMaxTemperature,
							weatherCode: code,
						} = data;
						const locationElement: HTMLElement | null =
							upcomingEvent.querySelector('.event-location');
						if (locationElement) {
							appendWeatherData(
								locationElement,
								Math.round(maxTemperature),
								Math.round(apparentMaxTemperature),
								Math.round(code),
							);
						}
					}
				} catch {
					// Fail silently.
				}
			})();
		}
	}
}
