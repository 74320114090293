type WebmentionProperty =
	| 'like-of'
	| 'repost-of'
	| 'in-reply-to'
	| 'mention-of';

type WebmentionAuthor = {
	type: 'card';
	name: string;
	url: string;
	photo: string;
};

type WebmentionContent = {
	html: string;
	text: string;
};

type Webmention = {
	type: 'entry';
	author: WebmentionAuthor;
	content: WebmentionContent;
	published: string | null;
	url: string;
	name?: string;
	syndication?: string[];
	rels?: Record<string, string>;
	'like-of'?: string;
	'repost-of'?: string;
	'in-reply-to'?: string;
	'mention-of'?: string;
	'wm-received': string;
	'wm-id': number;
	'wm-source': string;
	'wm-target': string;
	'wm-property': WebmentionProperty;
	'wm-private': boolean;
};

type WebmentionResponse = {
	children: Webmention[];
};

const getPlatform = (url: string) => {
	if (url.includes('twitter.com')) {
		return 'X';
	}

	if (url.includes('instagram.com')) {
		return 'Instagram';
	}

	if (url.includes('fed.brid.gy')) {
		return 'Mastodon';
	}

	return undefined;
};

const getPlatformHint = (item: Webmention) => {
	const {name, url} = item;

	const platform = getPlatform(url);
	if (platform) {
		return ` auf <a href="${url}" target="_blank" rel="noopener noreferrer" class="u-url">${platform}</a>`;
	}

	if (name) {
		return ` in „<a href="${url}" target="_blank" rel="noopener noreferrer" class="u-url">${name}</a>“`;
	}

	return ` <a href="${url}" rel="noopener noreferrer" class="u-url" aria-hidden="true" hidden></a>`;
};

const cleanUsername = (user: string) => user.replace(/:.+:$/, '').trim();

if (
	location.pathname.startsWith('/news') &&
	document.querySelectorAll('article').length === 1 &&
	window.fetch
) {
	const apiUrl = 'https://webmention.io/api/mentions.jf2';
	const parameters = [
		`target=https://instrumentalverein-tueddern.de${location.pathname}`,
		'token=qwDuMvJD_gCwuHTihV23XA',
		'per-page=100',
	];
	(async () => {
		try {
			const response = await window.fetch(
				apiUrl + '?' + parameters.join('&'),
			);
			const data = (await response.json()) as WebmentionResponse;
			if (data.children && data.children.length > 0) {
				const items = data.children
					.map((item: Webmention) => {
						const {url, author, content} = item;
						const photo = `<img src="${author.photo}" width="24" height="24" alt="" loading="lazy" class="u-photo">`;
						const authorCard = `<span class="p-author h-card">${photo} <a href="${
							author.url
						}" class="p-name u-url">${cleanUsername(
							author.name,
						)}</a></span>`;
						const text = content.html ?? content.text;
						const quote = `<blockquote class="e-content p-name" cite="${url}">${text}</blockquote>`;
						const platformHint = getPlatformHint(item);

						if (item['wm-property'] === 'like-of') {
							return `<li class="p-like h-cite">${authorCard} gefällt dieser Beitrag${platformHint}.</li>`;
						}

						if (item['wm-property'] === 'repost-of') {
							return `<li class="p-repost h-cite">${authorCard} hat diesen Beitrag${platformHint} geteilt.</li>`;
						}

						if (item['wm-property'] === 'in-reply-to') {
							return `<li class="u-comment h-cite">${authorCard} hat diesen Beitrag${platformHint} kommentiert: ${quote}</li>`;
						}

						if (item['wm-property'] === 'mention-of') {
							const showQuote = text.length < 288;
							const optionalQuote = showQuote
								? `: ${quote}`
								: '.';
							return `<li class="u-comment h-cite">${authorCard} hat diesen Beitrag${platformHint} erwähnt${optionalQuote}</li>`;
						}

						return '';
					})
					.filter(Boolean);

				if (items.length > 0) {
					const article = document.querySelector('main > article');
					if (article) {
						article.insertAdjacentHTML(
							'beforeend',
							`<ul class="webmentions">${items.join('')}</ul>`,
						);
					}
				}
			}
		} catch {
			// Do nothing.
		}
	})();
}
